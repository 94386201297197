import React, { Component } from 'react'

import Layout from '../Components/layout.js'
import SEO from '../Components/seo.js'
import Pictures from '../Components/Picture.jsx'
import TextWithPicture from '../Components/TextWithPicture.jsx'
import TextTitle from '../Components/TextTitle.jsx'
import Row from '../Components/Rows.jsx'
import TextPictureWithPictureTiles from '../Components/TextPictureWithPictureTiles.jsx'

/*

-Forever 21 Care Label
-Video of PeaPod
-Gildan, Tear away, AlStyles
-Fantasy, spectra
-Woven Labels
-Walmart Price Tags
-Law Label
-Correction Labels

*/

class Products extends Component {
  render() {
    return(
      <Layout>
        <SEO title='Products'/>
        <Pictures splash src={'Splash10'}/>

        <Row noPaddingBottom>
          <TextTitle title='Products'/>
        </Row>
        
        <TextPictureWithPictureTiles
          below
          alignPicture={'left'}
          pictureSize={'equal'}
          imgSrc={'SizeTeeAllThree'}
          title={'Printed Labels'}
          textContent={'Printed labels can be found everywhere and are the primary way of conveying information from the manufacturer to the consumer. They can come in a variety of materials and designs and are infinitely customizable. Visit the materials page to see various examples of printed labels.'}
          hashtags={[
            {
              title: 'Materials',
              hashtags: ['Satin','Cotton','Tear-away Acetate','Tyvek','Nylon']
            }
          ]}
          pictures={[
            { imgSrc: 'TwoNylonLabelsTop' },
            { imgSrc: 'PawDesignLabel' },
            { imgSrc: 'TwoSpectra' },
            { imgSrc: 'AlstyleTopAll' },
            { imgSrc: 'HoneywellLabels' },
            { imgSrc: 'ThreeSizeTeeLabel' },
            { imgSrc: 'FiveSpectra' },
            { imgSrc: 'FiveLabelsSpreadTop' },
            { imgSrc: 'ThreeLabels' },
            { imgSrc: 'ThreeTeestyledTop' },
            { imgSrc: 'AlstyleTop2' },
            { imgSrc: 'PawDesignSpreadThree2' }
          ]}
          cols={6}
        />

        <TextPictureWithPictureTiles
          below
          alignPicture={'right'}
          pictureSize={'equal'}
          imgSrc={'LotsOfPeaPod'}
          title={'Pea-Pod Booklet'}
          textContent={'Pea pod booklets are a specialty label that is perfect for care labels that require high information density. Utilizing a special type of nylon, the label remains sealed until ripped open which can be accomplished with a single finger\'s amount of force. We often receive orders for care labels that contain content translated in as many as 15 languages.'}
          hashtags={[
            {
              title: 'Materials',
              hashtags: ['Nylon']
            }
          ]}
          pictures={[
            { imgSrc: 'PeaPod5' },
            { imgSrc: 'PeaPod2' },
            { imgSrc: 'PeaPod' },
            { imgSrc: 'PeapodTop' },
            { imgSrc: 'PeapodSpread4Top' },
            { imgSrc: 'PeapodTop4' }
          ]}
          cols={6}
        />

        <Row>
          <TextWithPicture
            below
            alignPicture={'left'}
            pictureSize={'equal'}
            imgSrc={'WarningFourTop'}
            title={'Law Label'}
            textContent={'Law labels are tags or labels that are legally required by state or federal law for specific regulated products. They are found on mattresses, upholstery, and stuffed article products to inform consumers of hidden contents.'}
            hashtags={[
              {
                title: 'Materials',
                hashtags: ['Tyvek']
              }
            ]}
          />
        </Row>

        <TextPictureWithPictureTiles
          below
          alignPicture={'right'}
          pictureSize={'equal'}
          imgSrc={'StickerLabel'}
          title={'Adhesive Correction Label'}
          textContent={'Adhesive correction labels are opaque and can be used to cover up and replace the surface beneath. Our adhesive backing can withstand repeated washes, ensuring that the label stays in place. '}
          pictures={[
            { imgSrc: 'TwoStickerLabels' },
            { imgSrc: 'OneStickerLabel' },
            { imgSrc: 'OneMadeInChinaLabel' },
            { imgSrc: 'OneStickerLabel2' },
            { imgSrc: 'OneStickerRoll' }
          ]}
          cols={5}
        />     
      </Layout>
    )
  }
}

export default Products